import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import MaUTable from "@mui/material/Table";
import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableToolbar from "./TableToolbar";
import TablePaginationActions from "./TablePaginationActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-toastify/dist/ReactToastify.css";
import "@material-tailwind/react/tailwind.css";
import Button from "@material-tailwind/react/Button";
import TextInput from "./TextInput";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { fetchAPI } from "../lib/api";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const inputStyle = {
  padding: 0,
  margin: 0,
  border: 0,
  background: "transparent",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div style={inputStyle} onBlur={onBlur}>
      {value}
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "favoriteColor",
    "notes",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  return errors;
};

EditableCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any.isRequired,
  }),
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
  }),
  column: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  updateMyData: PropTypes.func.isRequired,
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

const SignUpTable = (
  {
    columns,
    data,
    setData,
    updateMyData,
    skipPageReset,
    activeEvent,
    initialState,
  },
  props
) => {
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchAPI("/invites").then((data) => setInvites(data));
  }, [loading]);
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data: invites,
      defaultColumn,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      initialState,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  const removeByIndexs = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i));

  const deleteUserHandler = (event) => {
    const newData = removeByIndexs(
      data,
      Object.keys(selectedRowIds).map((x) => parseInt(x, 10))
    );
    setData(newData);
  };

  const addUserHandler = (user) => {
    const newData = data.concat([user]);
    setData(newData);
  };
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [seats, setSeats] = useState("");
  const handleClickOpen = (values) => {
    setId(values[0])
    setName(values[1]);
    setSeats(values[2]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    const data = {
      title: e.name,
      seats: e.seats,
    };
    const jsonifiedData = JSON.stringify(data);

    axios
      .put(`https://vt-events.herokuapp.com/invites/${id}`, jsonifiedData, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleClose();
        toast.success("You have successfully updated invitation details");
        setLoading(!loading)
      })
      .catch((err) => {
        console.log("err", err.response.data.message);
        if (err.response.data.message === "An internal server error occurred") {
          toast.error(
            "An error has occurred. You might have been already registered. Please try again later"
          );
        }
      });
  };
  // Render the UI for your table
  return (
    <div>
      <TableContainer>
        <TableToolbar
          deleteUserHandler={deleteUserHandler}
          addUserHandler={addUserHandler}
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          activeEvent={activeEvent}
          setLoading={setLoading}
          loading={loading}
        />
        <MaUTable {...getTableProps()}>
          <TableHead style={{ backgroundColor: "#E5E5E5", textAlign: "right" }}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...(column.id === "selection"
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                    style={{ textAlign: "left", verticalAlign: "middle" }}
                  >
                    {column.render("Header")}
                    {column.id !== "selection" ? (
                      <TableSortLabel
                        active={column.isSorted}
                        // react-table has a unsorted state which is not treated here
                        direction={column.isSortedDesc ? "desc" : "asc"}
                      />
                    ) : null}
                  </TableCell>
                ))}
                <TableCell
                  style={{ textAlign: "left", verticalAlign: "middle" }}
                >
                  {null}
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.slice(0, 3).map((cell) => {
                    return (
                      <TableCell
                        style={{
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                  {row.cells.slice(3, 4).map((cell) => {
                    return (
                      <TableCell
                        style={{
                          textAlign: "left",
                          verticalAlign: "middle",
                        }}
                        {...cell.getCellProps()}
                      >
                        <CopyToClipboard
                          text={`https://vt-event-signup.vercel.app/?invitation=${cell.value}`}
                          onCopy={() => {
                            toast.success("Copied");
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              color: "#0000EE",
                            }}
                          >
                            https://vt-event-signup.vercel.app/?invitation={cell.render("Cell")}
                          </div>
                        </CopyToClipboard>
                      </TableCell>
                    );
                  })}
                  <TableCell
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    <Button
                      onClick={() =>
                        handleClickOpen(row.cells.map((el) => el.value))
                      }
                    >
                      Update invitation
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: invites.length },
                ]}
                colSpan={3}
                count={invites.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { "aria-label": "lignes par page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <Formik
            initialValues={{
              name: name,
              seats: seats,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(),
              seats: Yup.string().required(),
            })}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              values,
            }) => (
              <div class="flex flex-row-reverse" style={{ maxWidth: "100%" }}>
                <Form class="w-full max-w-lg" onSubmit={handleSubmit}>
                  <div class="flex mb-6">
                    <div class="w-full px-3 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-name"
                      >
                        Name
                      </label>
                      <TextInput
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                        error={errors.name && touched.name && errors.name}
                      />
                    </div>
                  </div>
                  <div class="flex mb-6">
                    <div class="w-full px-3 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-name"
                      >
                        Seats
                      </label>
                      <TextInput
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="number"
                        name="seats"
                        onChange={handleChange}
                        value={values.seats}
                        onBlur={handleBlur}
                        error={errors.seats && touched.seats && errors.seats}
                      />
                    </div>
                  </div>
                  <div class="flex items-center justify-end mt-2">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color={"lightBlue"}
                      ripple="light"
                      disabled={isSubmitting}
                    >
                      Update invitation{" "}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

SignUpTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
};
export default reduxForm({
  form: "MaterialUiForm", // a unique identifier for this form
  validate,
})(SignUpTable);
