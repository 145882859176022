import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import Button from "@material-tailwind/react/Button";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import "@material-tailwind/react/tailwind.css";
import TextInput from "./TextInput";

toast.configure();

export default function SignupForm({handleClose, setLoading, loading}) {


  const handleSubmit = async (e) => {
    const data = {
      title: e.name,
      seats: e.seats,
    };
    const jsonifiedData = JSON.stringify(data);

    axios
      .post(`https://vt-events.herokuapp.com/invites`, jsonifiedData, { headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      }})
      .then((res) => {
        handleClose();
        toast.success("You have successfully created a new invitation link");
        setLoading(!loading)

      })
      .catch((err) => {
        console.log("err", err.response.data.message);
        if (err.response.data.message === "An internal server error occurred") {
          toast.error(
            "An error has occurred. You might have been already registered. Please try again later"
          );
        }
      });
  };
  return (
    <Formik
      initialValues={{
        name: "",
        seats: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        seats: Yup.string().required(),
      })}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <div class="flex flex-row-reverse" style={{ maxWidth: "100%" }}>
          <Form class="w-full max-w-lg" onSubmit={handleSubmit}>
            <div class="flex mb-6">
              <div class="w-full px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-name"
                >
                  Name
                </label>
                <TextInput
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  onBlur={handleBlur}
                  error={errors.name && touched.name && errors.name}
                />
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-full px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-name"
                >
                  Seats
                </label>
                <TextInput
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="number"
                  name="seats"
                  onChange={handleChange}
                  value={values.seats}
                  onBlur={handleBlur}
                  error={errors.seats && touched.seats && errors.seats}
                />
              </div>
            </div>
            <div class="flex items-center justify-end mt-2">
              <Button
                type="submit"
                onClick={handleSubmit}
                color={"lightBlue"}
                ripple="light"
                disabled={isSubmitting}
              >
                Create invitation{" "}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
